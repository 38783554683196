.Root {
  padding: var(--section-padding) 0;
}

.Root.NoPadding {
  padding: 0;
}

.Root.NoPaddingTop {
  padding-top: 0;
}

.Root.NoPaddingBottom {
  padding-bottom: 0;
}

.HasSideBar {
  display: flex;
}

.HasSideBar > *:last-of-type {
  flex: 1;
}

.MarginTop {
  margin-top: 2.45rem;
}

/**
* For mobile
*/

@media (--viewport-mobile-down) {
  .Root {
    padding: calc(var(--section-padding) * 0.45) 0;

    /* padding: calc(var(--section-padding) * 0.7) 0;
    padding-bottom: calc(var(--section-padding) * 0.5); */
  }

  .MarginTop {
    margin-top: 0;
  }
}
