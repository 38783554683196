.Root {
  font-weight: 800;
  margin-bottom: var(--gutter);
}

.Splash em {
  color: var(--color-splash);
}

.Root .SubHeader {
  text-transform: uppercase;
  font-size: calc(var(--font-size-medium) * 1.3);
  line-height: calc(var(--font-size-medium) * 1.3);
  margin-bottom: calc(var(--gutter) * 0.5);
  /* margin-right: calc(var(--gutter) * 0.5); */
}

/* .PositionLeft {
} */
.PositionRight {
  text-align: right;
}
.PositionCenter {
  text-align: center;
}

.SubText {
  margin-top: calc(var(--gutter) * 1.5);
  font-weight: var(--font-weight-medium);
}
