.Root {
  font-weight: 800;
  line-height: 4.5rem;
  word-break: break-word;
}

.Root em {
  color: var(--color-brand);
  font-style: normal;
}

.UpperCase {
  text-transform: uppercase;
}

.BrandColor {
  color: var(--color-brand);
}

.FontSizeSuperSmall {
  font-size: calc(var(--font-size-medium) * 1);
  line-height: 2rem;
}
.FontSizeExtraSmall {
  font-size: calc(var(--font-size-medium) * 1.5);
  line-height: 2rem;
}
.FontSizeSmall {
  font-size: calc(var(--font-size-medium) * 2);
  line-height: 2.5rem;
}

.FontSizeMedium {
  font-size: calc(var(--font-size-medium) * 3);
  line-height: calc(var(--font-size-medium) * 3);
}
.FontSizeLarge {
  font-size: calc(var(--font-size-medium) * 4.15);
  line-height: calc(var(--font-size-medium) * 4.15);
}
.FontSizeExtraLarge {
  font-size: calc(var(--font-size-medium) * 4.15);
  line-height: calc(var(--font-size-medium) * 4.15);
}

/**
 * For mobile
 */

@media (--viewport-mobile-down) {
  .FontSizeExtraLarge {
    font-size: calc(var(--font-size-medium) * 2.2);
    line-height: calc(var(--font-size-medium) * 2.2);
  }

  .FontSizeLarge {
    font-size: calc(var(--font-size-medium) * 1.5);
    line-height: calc(var(--font-size-medium) * 1.5);
  }

  .FontSizeSmall {
    font-size: calc(var(--font-size-medium) * 1.2);
    line-height: calc(var(--font-size-medium) * 1.2);
  }
}
