.Image {
  object-fit: contain;
}

.ImageContainer {
  position: relative;
  margin-top: var(--gutter);
  height: 15rem;
}

.Button {
  margin-top: var(--gutter);
  min-width: 22rem;
}

@media screen and (--viewport-mobile-down) {
  .ImageContainer {
    height: 12rem;
  }
}
